// SuggestionSection.js

import React, { useEffect, useState } from 'react';
import './SuggestionSection.css';

function SuggestionSection({
  eventsCount,
  eventImportance,
  holidayNames,
  holidayEventInteraction,
  holidayEventImportance,
  prevDayPriceChange,
  prev3DayAvgPriceChange,
  isWeekend,
  daysToNextHoliday,
  suggestionResetTrigger,
  selectedAccommodation,
  setSelectedAccommodation,
  startDate,
  priceSuggestion, // Received from App.js
  isSuggestionBoxVisible, // Control visibility
}) {
  // Calculate month, day, and other date-related variables
  const month = startDate.getMonth() + 1;
  const day = startDate.getDate();
  const dayOfWeek = startDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  const holiday = holidayNames.length > 0 ? 1 : 0;

  // Log selectedAccommodation to verify its structure
  useEffect(() => {
    console.log('SuggestionSection - Selected Accommodation:', selectedAccommodation);
  }, [startDate, selectedAccommodation]);

  // **Define getCurrentPrice function**
  const getCurrentPrice = () => {
    if (!selectedAccommodation) return 0;

    let priceRaw;
    if (selectedAccommodation.ar) {
      priceRaw = selectedAccommodation.ar;
    } else if (selectedAccommodation.meta && selectedAccommodation.meta.ar) {
      priceRaw = selectedAccommodation.meta.ar;
    } else if (selectedAccommodation.acf && selectedAccommodation.acf.ar) {
      priceRaw = selectedAccommodation.acf.ar;
    } else {
      console.warn('Price ("ar") is missing in selectedAccommodation:', selectedAccommodation);
      return 0;
    }

    // Clean the price string if necessary
    const priceCleaned =
      typeof priceRaw === 'string' ? priceRaw.replace(/[^0-9.-]+/g, '') : priceRaw;

    const parsedPrice = parseFloat(priceCleaned);

    if (isNaN(parsedPrice)) {
      console.warn('Parsed price is NaN:', priceCleaned);
      return 0;
    }

    return parsedPrice;
  };

  // **Define getCurrency function**
  const getCurrency = () => {
    if (!selectedAccommodation) return 'Ft';

    let currencyRaw;
    if (selectedAccommodation.ar_kategoria) {
      currencyRaw = selectedAccommodation.ar_kategoria;
    } else if (selectedAccommodation.meta && selectedAccommodation.meta.ar_kategoria) {
      currencyRaw = selectedAccommodation.meta.ar_kategoria;
    } else if (selectedAccommodation.acf && selectedAccommodation.acf.ar_kategoria) {
      currencyRaw = selectedAccommodation.acf.ar_kategoria;
    } else {
      console.warn(
        'Currency ("ar_kategoria") is missing in selectedAccommodation:',
        selectedAccommodation
      );
      return 'Ft';
    }

    return currencyRaw;
  };

  const currentPrice = getCurrentPrice();
  const currency = getCurrency();

  // Calculate new price based on suggestion
  const calculateNewPrice = () => {
    if (!isNaN(currentPrice) && priceSuggestion !== null) {
      return (currentPrice * (1 + priceSuggestion / 100)).toFixed(1);
    }
    return 0;
  };

  // Generate star ratings based on criteria
  const renderStars = (rating) => {
    const stars = [];
    if (rating === 0) {
      // Display message or empty stars when rating is zero
      stars.push(
        <span key="no-stars" className="no-stars">
          Nincs értékelés
        </span>
      );
      return stars;
    }

    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(
          <span key={i} className="star filled">
            &#9733;
          </span>
        );
      } else if (i === fullStars + 1 && halfStar) {
        stars.push(
          <span key={i} className="star half">
            &#9733;
          </span>
        );
      } else {
        stars.push(
          <span key={i} className="star">
            &#9733;
          </span>
        );
      }
    }
    return stars;
  };

  // Define realistic maximums based on your data
  const MAX_EVENTS_COUNT = 50; // Adjust as needed
  const MAX_EVENT_IMPORTANCE = 10; // Adjust based on expected maximum
  const MAX_HOLIDAY_IMPORTANCE = 50; // Adjust based on your data

  // 1. Tourist Appeal
  const touristAppeal = (() => {
    // Normalize eventsCount and holidayEventImportance
    const normalizedEvents = Math.min(eventsCount / MAX_EVENTS_COUNT, 1); // 0 to 1
    const normalizedHolidayImportance = Math.min(holidayEventInteraction / MAX_HOLIDAY_IMPORTANCE, 1); // 0 to 1

    // Assign weights to each factor
    const eventsWeight = 3; // Maximum contribution from events
    const holidayWeight = 1.5; // Maximum contribution from holidays
    const weekendBonus = isWeekend ? 0.5 : 0; // Bonus for weekends

    // Calculate composite score
    const compositeScore =
      normalizedEvents * eventsWeight +
      normalizedHolidayImportance * holidayWeight +
      weekendBonus;

    // Clamp the final rating between 1 and 5
    return Math.min(5, Math.max(1, compositeScore));
  })();

  // 2. Event Attraction
  const eventAttraction = (() => {
    // Normalize eventImportance to a 0-5 scale
    const normalizedImportance = (eventImportance / MAX_EVENT_IMPORTANCE) * 5;

    // Clamp between 1 and 5
    return Math.min(5, Math.max(1, normalizedImportance));
  })();

  // 3. Holiday Impact
  const holidayImpact = (() => {
    if (holiday > 0) {
      // Normalize holidayEventImportance to a 0-5 scale
      const normalizedHolidayImportance = (holidayEventInteraction / MAX_HOLIDAY_IMPORTANCE) * 5;

      // Clamp between 1 and 5
      return Math.min(5, Math.max(1, normalizedHolidayImportance));
    } else {
      // No holiday impact
      return 0; // Zero rating when no holidays
    }
  })();

  // State to manage tooltip visibility
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);

  // Function to determine criteria ratings, now including descriptions
  const determineCriteriaRatings = () => {
    return [
      {
        name: 'Turisztikai vonzerő',
        rating: touristAppeal,
        description: 'Ez a mutató a turisztikai látnivalók és vonzerők hatását méri az árazásra.',
      },
      {
        name: 'Események vonzereje',
        rating: eventAttraction,
        description: 'Ez a mutató az események és rendezvények árazásra gyakorolt hatását mutatja.',
      },
      {
        name: 'Ünnepek hatása',
        rating: holidayImpact,
        description: 'Ez a mutató az ünnepnapok árazásra gyakorolt hatását jelzi.',
      },
    ];
  };

  const criteria = determineCriteriaRatings();

  // Generate summary based on priceSuggestion
  const generateSummary = () => {
    if (priceSuggestion < 5) {
      return 'Ez a nap nem ideális az áremelésre az aktuális esemény- és ünnepnapok adatai alapján. Fontold meg a jelenlegi árak fenntartását, hogy versenyképes maradj.';
    } else if (priceSuggestion >= 5 && priceSuggestion < 20) {
      return 'A mai napon mérsékelt áremelés ajánlott. A rendezvények és/vagy ünnepek kombinációja egészséges keresletre utal, ami lehetővé teszi a bevétel maximalizálása érdekében történő enyhe igazítást.';
    } else if (priceSuggestion >= 20 && priceSuggestion < 50) {
      return 'Célszerű jelentős áremelést végrehajtani. A több eseményből és ünnepnapból adódó nagy kereslet a bevételek optimalizálása érdekében jelentős ármódosítást tesz szükségessé.';
    } else {
      return 'Ez egy fantasztikus nap az áremeléshez! A jelentős események és ünnepek miatti kivételes kereslet tökéletes lehetőséget biztosít a szállás bevételeinek maximalizálására.';
    }
  };

  return (
    <div className="suggestion-section">
      {isSuggestionBoxVisible && (
        <div className="suggestion-box">
          <div className="columns">
            {/* Column 1: Suggested Percentage and Price Details */}
            <div className="column">
              <h3>Ajánlott emelés</h3>
              <p className="percentage">
                <center>+{priceSuggestion.toFixed(1)}%</center>
              </p>
            </div>

            {/* Column 2: Star Rating System */}
            <div className="column">
              <h3>Az árat befolyásoló tényezők</h3>
              {criteria.map((criterion, index) => (
  <div key={index} className="criterion">
    <div className="criterion-name">
      <span>{criterion.name}</span>
      <div
        className="info-icon-container"
        onClick={() =>
          setVisibleTooltipIndex(index === visibleTooltipIndex ? null : index)
        }
        onMouseLeave={() => setVisibleTooltipIndex(null)}
      >
        <img
          src="/wp-content/icons/info.png"
          alt="info"
          className="info-icon"
        />
        {visibleTooltipIndex === index && (
          <div className="tooltip">{criterion.description}</div>
        )}
      </div>
    </div>
    <div className="stars">{renderStars(criterion.rating)}</div>
  </div>
))}
            </div>

            {/* Column 3: Summary */}
            <div className="column">
              <h3>Összegzés</h3>
              <p>{generateSummary()}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuggestionSection;
