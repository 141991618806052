// CalendarView.js

import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import {
  startOfDay,
  endOfDay,
  addDays,
  format,
} from 'date-fns';
import hu from 'date-fns/locale/hu';
import 'react-calendar/dist/Calendar.css';
import './CalendarView.css';

function CalendarView(props) {
  const {
    startDate,
    setStartDate,
    isLoading,
  } = props;

  const [demandData, setDemandData] = useState({});
  const [dots, setDots] = useState('');
  const dotsInterval = useRef(null);
  const calendarRef = useRef(null);

  // Fetch demand data from server
  useEffect(() => {
    const fetchDemandData = async () => {
      try {
        const response = await fetch('/wp-json/wp/v2/get-demand-data', {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}`);
        }

        const data = await response.json();

        // Convert the data into a suitable format
        const demand = {};
        data.forEach((item) => {
          demand[item.date] = item.percentage;
        });

        setDemandData(demand);
      } catch (error) {
        console.error('Failed to fetch demand data:', error);
      }
    };

    fetchDemandData();
  }, []);

  // Use tileClassName to assign classes based on demand
  const tileClassName = ({ date, view, activeStartDate }) => {
    if (view !== 'month') return null;

    // Check if the date is a neighboring month date
    if (date.getMonth() !== activeStartDate.getMonth()) {
      return null; // Do not assign demand level classes to neighboring month dates
    }

    const dateStr = format(date, 'yyyy-MM-dd');

    // Check if the date is disabled (before minDate or after maxDate)
    const minDate = startOfDay(new Date());
    const maxDate = endOfDay(addDays(new Date(), 90));

    if (date < minDate || date > maxDate) {
      return null; // Do not assign any class to disabled dates
    }

    const demandPercentage = demandData[dateStr] || 0;

    // Adjust thresholds based on demandPercentage
    if (demandPercentage >= 25) {
      return 'high-demand';
    } else if (demandPercentage >= 10) {
      return 'medium-demand';
    } else if (demandPercentage >= 2) {
      return 'low-demand';
    } else {
      return 'no-demand';
    }
  };

  // Animated dots for loading state
  useEffect(() => {
    if (isLoading) {
      dotsInterval.current = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
    } else {
      clearInterval(dotsInterval.current);
      setDots('');
    }

    return () => clearInterval(dotsInterval.current);
  }, [isLoading]);

  // Function to handle date change
  const onDateClick = (date) => {
    setStartDate(date);
    // Do not open the suggestion section
  };

  return (
    <div className="calendar-view" ref={calendarRef}>
      {/* Wrapper for Legend */}
      <div className="calendar-legend-wrapper">
        <div className="calendar-legend">
          <div>
            <span style={{ backgroundColor: '#90ee90' }} /> Nagy forgalom
          </div>
          <div>
            <span style={{ backgroundColor: '#ffff66' }} /> Közepes forgalom
          </div>
          <div>
            <span style={{ backgroundColor: '#ffa500' }} /> Alacsony forgalom
          </div>
          <div>
            <span style={{ backgroundColor: '#ff4d4d' }} /> Nincs forgalom
          </div>
        </div>
      </div>

      <div className="calendar-container">
        {isLoading && <div className="calendar-overlay"></div>}
        <Calendar
          onClickDay={onDateClick}
          value={startDate}
          tileClassName={tileClassName}
          minDate={new Date()}
          maxDate={addDays(new Date(), 90)}
          locale="hu"
          prevLabel="<"
          nextLabel=">"
          navigationLabel={({ date }) =>
            format(date, 'yyyy MMMM', { locale: hu })
          }
          tileDisabled={() => isLoading}
        />
        {isLoading && (
          <div className="calendar-loading">
            Betöltés{dots}
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarView;
