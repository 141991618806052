import React, { useState, useRef, useEffect } from 'react';
import Map, { Marker, Popup, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import './MapSection.css';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash.debounce';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const darkStyle = 'mapbox://styles/smartbuild24/cm0ggac6d002301pj1u0pfqq9';
const lightStyle = 'mapbox://styles/smartbuild24/cm4ia9bir007x01sfbmqr355j';

const MapSection = ({
  accommodations,
  filteredVenues,
  selectedAccommodation,
  setSelectedAccommodation,
  selectedEvent,
  setSelectedEvent,
  mapCenter,
  setMapCenter,
  isBottomSectionOpen,
  isDarkMode
}) => {
  const mapRef = useRef();
  const [popupInfo, setPopupInfo] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  // Track if the selection came from the popup
  const chosenFromPopupRef = useRef(false);

  const bounds = [
    [18.6612, 47.3516],
    [19.5575, 47.6073],
  ];

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (mapRef.current && mapLoaded) {
      const newStyle = isDarkMode ? darkStyle : lightStyle;
      mapRef.current.getMap().setStyle(newStyle);
    }
  }, [isDarkMode, mapLoaded]);

  useEffect(() => {
    if (selectedEvent && mapLoaded) {
      const { venue } = selectedEvent;
      if (venue) {
        const longitude = parseFloat(venue.longitude);
        const latitude = parseFloat(venue.latitude);

        mapRef.current.getMap().flyTo({
          center: [longitude, latitude],
          zoom: 13,
          essential: true,
        });

        setPopupInfo({
          ...venue,
          events: [selectedEvent],
        });
      }
    }
  }, [selectedEvent, mapLoaded]);

  useEffect(() => {
    if (selectedAccommodation && mapLoaded) {
      if (chosenFromPopupRef.current) {
        // Just chosen from popup, skip showing popup again
        chosenFromPopupRef.current = false; 
      } else {
        // Externally selected accommodation, show popup
        const longitude = parseFloat(selectedAccommodation.longitude);
        const latitude = parseFloat(selectedAccommodation.latitude);

        mapRef.current.getMap().flyTo({
          center: [longitude, latitude],
          zoom: 13,
          essential: true,
        });

        setPopupInfo(selectedAccommodation);
      }
    }
  }, [selectedAccommodation, mapLoaded]);

  useEffect(() => {
    if (mapCenter && mapLoaded) {
      const [longitude, latitude] = mapCenter;
      mapRef.current.getMap().flyTo({
        center: [parseFloat(longitude), parseFloat(latitude)],
        essential: true,
      });
    }
  }, [mapCenter, mapLoaded]);

  useEffect(() => {
    if (mapRef.current && mapLoaded) {
      setTimeout(() => {
        mapRef.current.getMap().resize();
      }, 300);
    }
  }, [isBottomSectionOpen, mapLoaded]);

  useEffect(() => {
    if (!mapRef.current || !mapRef.current.getMap) return;

    const handleResize = debounce(() => {
      if (mapRef.current) {
        mapRef.current.getMap().resize();
      }
    }, 200);

    const resizeObserver = new ResizeObserver(handleResize);
    const mapContainer = mapRef.current.getMapContainer();

    if (mapContainer) {
      resizeObserver.observe(mapContainer);
    }

    return () => {
      if (mapContainer) {
        resizeObserver.unobserve(mapContainer);
      }
      resizeObserver.disconnect();
      handleResize.cancel();
    };
  }, []);

  const isSelected = selectedAccommodation && popupInfo && selectedAccommodation.id === popupInfo.id;

  return (
    <div className="MapSection">
      <Map
        initialViewState={{
          longitude: 19.0402,
          latitude: 47.4979,
          zoom: 12,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={darkStyle} 
        ref={mapRef}
        onLoad={handleMapLoad}
        maxBounds={bounds}
        maxZoom={18}
        minZoom={11}
        maxPitch={70}
        attributionControl={false}
      >
        <NavigationControl position="top-right" />

        {mapLoaded &&
          accommodations.map((accommodation) => (
            <Marker
              key={accommodation.id}
              className="custom-house"
              longitude={parseFloat(accommodation.longitude)}
              latitude={parseFloat(accommodation.latitude)}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(accommodation);
              }}
            >
              <img
                src="/wp-content/icons/house.png"
                alt="Accommodation"
                style={{ width: 40, height: 40 }}
              />
            </Marker>
          ))}

        {mapLoaded &&
          filteredVenues.map((venue) => (
            <Marker
              key={venue.id}
              className="custom-marker"
              longitude={parseFloat(venue.longitude)}
              latitude={parseFloat(venue.latitude)}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(venue);
              }}
            >
              <img
                src="/wp-content/icons/location.png"
                alt="Venue"
                style={{ width: 25, height: 25 }}
              />
            </Marker>
          ))}

        {popupInfo && (
          <Popup
            longitude={parseFloat(popupInfo.longitude)}
            latitude={parseFloat(popupInfo.latitude)}
            anchor="bottom"
            offset={[0, -15]}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}
            className="custom-popup"
          >
            <div className="popup-content">
              {popupInfo.szallas_nev && (
                <div>
                  <strong>{popupInfo.szallas_nev}</strong>
                  <br />
                  {popupInfo.szallas_cim}
                  <br />
                  <button
                    className="map-popup-select-button"
                    onClick={() => {
                      const originalAccommodation = accommodations.find(
                        (acc) => acc.id === popupInfo.id
                      );
                      if (originalAccommodation && typeof setSelectedAccommodation === 'function') {
                        // This selection came from the popup
                        chosenFromPopupRef.current = true;
                        setSelectedAccommodation(originalAccommodation);
                        setMapCenter([originalAccommodation.longitude, originalAccommodation.latitude]);
                        // Close the popup immediately after choosing via the popup
                        setPopupInfo(null);
                      } else {
                        console.warn('Original accommodation not found or setSelectedAccommodation is not a function.');
                      }
                    }}
                    style={{
                      marginTop: '10px',
                      padding: '5px 10px',
                      cursor: isSelected ? 'not-allowed' : 'pointer',
                    }}
                    disabled={isSelected}
                  >
                    {isSelected
                      ? 'Ez a kiválasztott szállás'
                      : 'Válaszd ezt a szállást'}
                  </button>
                </div>
              )}
              {popupInfo.venue_name && (
                <div>
                  <strong>{`A napi események itt:`}</strong>
                  <br />
                  {popupInfo.events &&
                    popupInfo.events.map((event) => (
                      <li key={event.id}>
                        {event.event_name}
                      </li>
                    ))}
                  <br />
                  <strong style={{ fontSize: '1.2vh' }}>{popupInfo.venue_name}</strong>
                  <br />
                  {popupInfo.venue_address}
                  <br />
                </div>
              )}
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default MapSection;
