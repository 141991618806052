import React, { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import './TopSection.css';

function TopSection(props) {
  const {
    accommodations,
    selectedAccommodation,
    setSelectedAccommodation,
    setMapCenter,
    currentView,
    setCurrentView,
    startDate,
    setStartDate,
    filters,
    setFilters,
    handleGetPriceSuggestion,
    suggestionButtonState,
    handleResetSuggestion,
    isBottomSectionOpen,
    setIsBottomSectionOpen,
    bottomContainerHeight,
    toggleMapStyle,
    isDarkMode
  } = props;

  const [accommodationDropdownVisible, setAccommodationDropdownVisible] = useState(false);
  const [dots, setDots] = useState('');
  const [buttonsVisible, setButtonsVisible] = useState(true);

  const isMobileDevice = () => {
    return (
      window.innerWidth <= 768 ||
      (window.innerWidth >= 768 &&
        window.innerWidth <= 1024 &&
        window.matchMedia('(orientation: portrait)').matches)
    );
  };

  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const accommodationRef = useRef(null);

  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  const handleAccommodationClick = (accommodation) => {
    setSelectedAccommodation(accommodation);
    setMapCenter([accommodation.longitude, accommodation.latitude]);
    setAccommodationDropdownVisible(false);
  };

  const handleEventTypeClick = (type) => {
    setFilters({ ...filters, [type]: !filters[type] });
  };

  const handleButtonClick = () => {
    if (suggestionButtonState === 'idle') {
      handleGetPriceSuggestion(startDate);
    } else if (suggestionButtonState === 'completed') {
      handleResetSuggestion();
    }
  };

  const handleToggleView = () => {
    if (currentView === 'calendar') {
      setCurrentView('events-map');
    } else {
      setCurrentView('calendar');
      if (isMobile && isBottomSectionOpen) {
        setIsBottomSectionOpen(false);
      }
    }
  };

  const handleEventsButtonClick = () => {
    if (isBottomSectionOpen) {
      setIsBottomSectionOpen(false);
    } else {
      setIsBottomSectionOpen(true);
      if (isMobile && currentView === 'calendar') {
        setCurrentView('events-map');
      }
    }
  };

  const handleClickOutside = (event) => {
    if (accommodationRef.current && !accommodationRef.current.contains(event.target)) {
      setAccommodationDropdownVisible(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isPortraitTablet = window.matchMedia(
        '(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)'
      ).matches;

      const isMobileDevice = window.innerWidth <= 768 || isPortraitTablet;
      setIsMobile(isMobileDevice);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (suggestionButtonState === 'loading') {
      interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
    } else {
      setDots('');
    }
    return () => clearInterval(interval);
  }, [suggestionButtonState]);

  // Determine the icon to display based on the mode
  const modeIcon = isDarkMode ? '/wp-content/icons/sun.png' : '/wp-content/icons/moon.png';

  // Mobile Layout
  if (isMobile) {
    return (
      <div className="top-section">
        <div className="accommodation-selector" ref={accommodationRef}>
          <button
            className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
            onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
          >
            {selectedAccommodation
              ? selectedAccommodation.szallas_nev
              : 'Válassz a szállásaid közül!'}
          </button>
          {accommodationDropdownVisible && (
            <div className="dropdown-list accommodation-dropdown">
              {accommodations.length > 0 ? (
                accommodations.map((accommodation) => (
                  <div
                    key={accommodation.id}
                    className={`accommodation-item ${
                      selectedAccommodation === accommodation ? 'active' : ''
                    }`}
                    onClick={() => handleAccommodationClick(accommodation)}
                  >
                    <strong>{accommodation.szallas_nev}</strong>
                    <br />
                    <small>{accommodation.szallas_cim}</small>
                  </div>
                ))
              ) : (
                <p>Nincs még szállásod, adj hozzá egyet mielőtt tovább lépnél.</p>
              )}
            </div>
          )}
        </div>

        <div className="suggestion-button-container">
          <button
            onClick={handleButtonClick}
            disabled={suggestionButtonState === 'loading' || !selectedAccommodation}
            className={`suggestion-button ${
              suggestionButtonState === 'loading'
                ? 'loading'
                : suggestionButtonState === 'completed'
                ? 'completed'
                : ''
            } ${selectedAccommodation ? 'visible' : ''}`}
          >
            {suggestionButtonState === 'idle' && 'LÁSSAM AZ AJÁNLÁST!'}
          </button>
          <button
            onClick={toggleMapStyle}
            className="modeswitch-button"
          >
            <img src={modeIcon} alt="Toggle Map Mode" style={{ width: '2em', height: '2em' }} />
          </button>
        </div>

        <div className="event-filters-and-toggle">
          <div className="event-filter-types">
            {Object.keys(eventIcons).map((type) => (
              <button
                key={type}
                className={`event-icon-button ${filters[type] ? 'active' : ''}`}
                onClick={() => handleEventTypeClick(type)}
              >
                <img
                  src={filters[type] ? eventIcons[type].colored : eventIcons[type].colorless}
                  alt={type}
                  className="event-icon-image"
                />
                <span className="event-icon-text">{type}</span>
              </button>
            ))}
          </div>
          <div className={`view-switch-buttons ${buttonsVisible ? 'visible' : ''}`}>
            <button onClick={handleEventsButtonClick} className="event-view-button">
              {isBottomSectionOpen ? 'Vissza' : 'Események'}
            </button>
            <button onClick={handleToggleView} className="toggle-view-button">
              {format(startDate, 'yyyy MM. dd.', { locale: hu })}
            </button>
          </div>
        </div>
      </div>
    );
  }

  // Desktop Layout
  return (
    <div className="top-section">
      <div className="top-section-left">
        <div className="accommodation-selector" ref={accommodationRef}>
          <button
            className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
            onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
          >
            {selectedAccommodation
              ? selectedAccommodation.szallas_nev
              : 'Válassz a szállásaid közül!'}
          </button>
          {accommodationDropdownVisible && (
            <div className="dropdown-list accommodation-dropdown">
              {accommodations.length > 0 ? (
                accommodations.map((accommodation) => (
                  <div
                    key={accommodation.id}
                    className={`accommodation-item ${
                      selectedAccommodation === accommodation ? 'active' : ''
                    }`}
                    onClick={() => handleAccommodationClick(accommodation)}
                  >
                    <strong>{accommodation.szallas_nev}</strong>
                    <br />
                    <small>{accommodation.szallas_cim}</small>
                  </div>
                ))
              ) : (
                <p>Nincs még szállásod, adj hozzá egyet mielőtt tovább lépnél.</p>
              )}
            </div>
          )}
        </div>

        <div className={`view-switch-buttons ${buttonsVisible ? 'visible' : ''}`}>
          <button onClick={handleToggleView} className="toggle-view-button">
            {format(startDate, 'yyyy MM. dd.', { locale: hu })}
          </button>
        </div>

        <div className="event-filter-types">
          {Object.keys(eventIcons).map((type) => (
            <button
              key={type}
              className={`event-icon-button ${filters[type] ? 'active' : ''}`}
              onClick={() => handleEventTypeClick(type)}
            >
              <img
                src={filters[type] ? eventIcons[type].colored : eventIcons[type].colorless}
                alt={type}
                className="event-icon-image"
              />
              <span className="event-icon-text">{type}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="top-section-right">
        <div className="suggestion-button-container">
          <button
            onClick={handleButtonClick}
            disabled={suggestionButtonState === 'loading' || !selectedAccommodation}
            className={`suggestion-button ${
              suggestionButtonState === 'loading'
                ? 'loading'
                : suggestionButtonState === 'completed'
                ? 'completed'
                : ''
            } ${selectedAccommodation ? 'visible' : ''}`}
          >
            {suggestionButtonState === 'idle' && 'LÁSSAM AZ AJÁNLÁST!'}
          </button>
        </div>
        <div className="modeswitch-button-container" style={{marginLeft: '1vh'}}>
          <button
            onClick={toggleMapStyle}
            className="modeswitch-button"
          >
            <img src={modeIcon} alt="Toggle Map Mode" style={{ width: '2em', height: '2em' }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default TopSection;
