import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './styles/Animations.css'; 
import Sidebar from './components/Sidebar';
import MapSection from './components/MapSection';
import BottomSection from './components/BottomSection';
import SuggestionSection from './components/SuggestionSection';
import TopSection from './components/TopSection';
import CalendarView from './components/CalendarView';
import AdminDemandUpdater from './components/AdminDemandUpdater';
import axios from 'axios';
import {
  startOfDay,
  endOfDay,
  parseISO,
  isWithinInterval,
  isValid,
} from 'date-fns';

// Utility functions
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; 
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1); 
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function deg2rad(deg) {
  return (deg * Math.PI) / 180;
}

function App() {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [currentUserId, setCurrentUserId] = useState(null);
  const [priceSuggestion, setPriceSuggestion] = useState(null);
  const [isSuggestionBoxVisible, setIsSuggestionBoxVisible] = useState(false);
  const [suggestionButtonState, setSuggestionButtonState] = useState('idle');

  const contentRef = useRef(null);
  const sidebarRef = useRef(null);

  // Filters
  const [filters, setFilters] = useState({
    Zene: false,
    Kultúra: false,
    Sport: false,
    Színház: false,
  });

  // Data
  const [accommodations, setAccommodations] = useState([]);
  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filteredVenues, setFilteredVenues] = useState([]);

  // Selected states
  const [selectedAccommodation, setSelectedAccommodation] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Map
  const [mapCenter, setMapCenter] = useState([19.0402, 47.4979]); 
  const [currentView, setCurrentView] = useState('events-map');

  // Computed values
  const [eventsCount, setEventsCount] = useState(0);
  const [eventImportance, setEventImportance] = useState(0);
  const [holidayNames, setHolidayNames] = useState([]);
  const [holidayEventInteraction, setHolidayEventInteraction] = useState(0);
  const [holidayEventImportance, setHolidayEventImportance] = useState(0);
  const [dayOfWeek, setDayOfWeek] = useState(0);
  const [isWeekend, setIsWeekend] = useState(0);
  const [daysToNextHoliday, setDaysToNextHoliday] = useState(0);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isBottomSectionOpen, setIsBottomSectionOpen] = useState(!isMobile);
  const [bottomContainerHeight, setBottomContainerHeight] = useState(50);

  const [isDarkMode, setIsDarkMode] = useState(true);
  const toggleMapStyle = () => setIsDarkMode((prev) => !prev);
  
  const [accommodationDropdownVisible, setAccommodationDropdownVisible] = useState(false);


  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.remove('light-mode');
    } else {
      document.body.classList.add('light-mode');
    }
  }, [isDarkMode]);

  // NEW: Show overlay until an accommodation is chosen
  const [showInitialOverlay, setShowInitialOverlay] = useState(true);

  useEffect(() => {
    if (selectedAccommodation) {
      // Once an accommodation is chosen, hide the overlay
      setShowInitialOverlay(false);
    }
  }, [selectedAccommodation]);

  useEffect(() => {
    if (window.WPAPI && window.WPAPI.userID) {
      setCurrentUserId(parseInt(window.WPAPI.userID, 10));
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const venuesResponse = await axios.get('/wp-json/wp/v2/custom_venues');
        setVenues(venuesResponse.data || []);
      } catch (error) {
        console.error('Error fetching venues:', error);
        setVenues([]);
      }

      try {
        const accommodationsResponse = await axios.get(
          '/wp-json/wp/v2/user_accommodations',
          {
            headers: { 'X-WP-Nonce': window.WPAPI?.nonce },
          }
        );
        setAccommodations(accommodationsResponse.data || []);
      } catch (error) {
        console.error('Error fetching accommodations:', error);
        setAccommodations([]);
      }

      try {
        const eventsResponse = await axios.get('/wp-json/wp/v2/events');
        setEvents(eventsResponse.data || []);
      } catch (error) {
        console.error('Error fetching events:', error);
        setEvents([]);
      }

      try {
        const holidaysResponse = await axios.get('/wp-json/wp/v2/holidays');
        setHolidays(holidaysResponse.data || []);
      } catch (error) {
        console.error('Error fetching holidays:', error);
        setHolidays([]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (venues.length > 0 && events.length > 0) {
      const selectedDayStart = startOfDay(startDate);
      const selectedDayEnd = endOfDay(startDate);

      let filteredEventsList = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);
        return (
          isValid(eventDate) &&
          isWithinInterval(eventDate, {
            start: selectedDayStart,
            end: selectedDayEnd,
          })
        );
      });

      const activeFilters = Object.keys(filters).filter((key) => filters[key]);
      if (activeFilters.length > 0) {
        filteredEventsList = filteredEventsList.filter((event) =>
          activeFilters.includes(event.event_type)
        );
      }

      const filteredVenuesData = venues
        .map((venue) => {
          const venueEvents = filteredEventsList.filter(
            (event) => event.event_venue === venue.venue_name
          );
          return {
            ...venue,
            distanceInKm: null,
            events: venueEvents,
          };
        })
        .filter((venue) => venue.events.length > 0);

      setFilteredVenues(filteredVenuesData);
    } else {
      setFilteredVenues([]);
    }
  }, [venues, events, startDate, filters]);

  const handleDateChange = async (date) => {
    if (isLoading) return;
    if (!selectedAccommodation) {
      console.error('No accommodation selected.');
      return;
    }

    setIsLoading(true);
    setStartDate(date);
    await handleGetPriceSuggestion(date);
  };


  // Function to compute variables needed for price suggestion
  const computeVariables = (date, selectedAccommodation) => {
    let eventsCountValue = 0;
    let eventImportanceValue = 0;
    let holidayNamesValue = [];
    let holidayEventInteractionValue = 1;
    let holidayEventImportanceValue = 1;
    let dayOfWeekValue = 0;
    let isWeekendValue = 0;
    let daysToNextHolidayValue = 0;

    if (
      events.length > 0 &&
      selectedAccommodation &&
      holidays.length >= 0 && // Allow for zero holidays
      venues.length > 0
    ) {
      // 1. Compute eventsCount
      const selectedDayStart = startOfDay(date);
      const selectedDayEnd = endOfDay(date);

      const eventsOnSelectedDate = events.filter((event) => {
        const eventStartStr = event.event_start.replace(' ', 'T');
        const eventDate = parseISO(eventStartStr);

        if (!isValid(eventDate)) {
          console.warn('Invalid event date:', event.event_start);
          return false;
        }

        return isWithinInterval(eventDate, {
          start: selectedDayStart,
          end: selectedDayEnd,
        });
      });

      eventsCountValue = eventsOnSelectedDate.length;
      console.log('Events Count:', eventsCountValue); // Debugging

      // **Include highRatedEvents**
      const highRatedEvents = eventsOnSelectedDate.filter((event) => {
        const eventRating = parseFloat(event.event_rating) || 0;
        return eventRating >= 6;
      });

      // 2. Compute eventImportance by summing adjusted ratings with multipliers
      let totalAdjustedRating = 0;

      highRatedEvents.forEach((event) => {
        const venue = venues.find((v) => v.venue_name === event.event_venue);
        if (venue) {
          const venueLongitude = parseFloat(venue.longitude);
          const venueLatitude = parseFloat(venue.latitude);
          const accommodationLongitude = parseFloat(
            selectedAccommodation.longitude
          );
          const accommodationLatitude = parseFloat(
            selectedAccommodation.latitude
          );

          const distanceInKm = getDistanceFromLatLonInKm(
            accommodationLatitude,
            accommodationLongitude,
            venueLatitude,
            venueLongitude
          );

          // Corrected distance factor calculation
          let distanceFactor = 1.1; // Max factor for closest events
          if (distanceInKm >= 0 && distanceInKm <= 10) {
            distanceFactor = 1.01 + ((10 - distanceInKm) * 0.09) / 10;
          } else if (distanceInKm > 10) {
            distanceFactor = 1.01; // Min factor for distant events
          }

          const eventRating = parseFloat(event.event_rating) || 0;

          // **Determine Multiplier Based on Event Rating**
          let multiplier = 1; // Default multiplier
          switch (Math.round(eventRating)) {
            case 10:
              multiplier = 3.5;
              break;
            case 9:
              multiplier = 2.5;
              break;
            case 8:
              multiplier = 1.5;
              break;
            case 7:
              multiplier = 1.25;
              break;
            case 6:
              multiplier = 1;
              break;
            default:
              multiplier = 1; // Fallback for unexpected ratings
          }

          const adjustedRating = eventRating * distanceFactor * multiplier;
          totalAdjustedRating += adjustedRating;
		}
      });

      // **Divide totalAdjustedRating by highRatedEvents.length**
      if (highRatedEvents.length > 0) {
        eventImportanceValue = totalAdjustedRating / highRatedEvents.length;
      }
      console.log('Event Importance:', eventImportanceValue);

      // 3. Compute holidayEventInteraction

      // 3.1 Define holidaysOnSelectedDate
      const holidaysOnSelectedDate = holidays.filter((holiday) => {
        const holidayStart = parseISO(holiday.start_date);
        const holidayEnd = holiday.end_date
          ? parseISO(holiday.end_date)
          : holidayStart;

        if (!isValid(holidayStart)) {
          console.warn('Invalid holiday start date:', holiday.start_date);
          return false;
        }

        if (!isValid(holidayEnd)) {
          console.warn('Invalid holiday end date:', holiday.end_date);
          return false;
        }

        return isWithinInterval(selectedDayStart, {
          start: startOfDay(holidayStart),
          end: endOfDay(holidayEnd),
        });
      });

      // 3.2 Group holidays by name
      const holidayGroups = holidaysOnSelectedDate.reduce((groups, holiday) => {
        const name = holiday.holiday;
        if (!groups[name]) {
          groups[name] = [];
        }
        groups[name].push(holiday);
        return groups;
      }, {});

      // 3.3 Sum 'change' values for holidays with the same name
      const summedChanges = Object.values(holidayGroups).map((group) => {
        return group.reduce((sum, holiday) => {
          const changeValue = parseFloat(holiday.change) || 0; // Default to 0
          return sum + changeValue;
        }, 0);
      });

      // 3.4 Average the summed changes
      const totalChange = summedChanges.reduce((sum, val) => sum + val, 0);

      // 3.5 Calculate holidayEventInteraction
      const numberOfHolidayGroups = Object.keys(holidayGroups).length;
      holidayEventInteractionValue =
        numberOfHolidayGroups > 0
          ? totalChange / numberOfHolidayGroups
          : eventImportanceValue || 1; // Set to 1 if no holidays
      console.log(
        'Holiday Event Interaction:',
        holidayEventInteractionValue
      ); // Debugging

      // 3.6 Compute holidayEventImportance with adjusted logic
      if (eventImportanceValue === 0) {
        holidayEventImportanceValue = holidayEventInteractionValue;
      } else {
        holidayEventImportanceValue =
          (holidayEventInteractionValue * (1 + eventImportanceValue * 2)) / 10;
      }
      console.log('Holiday Event Importance:', holidayEventImportanceValue); // Debugging

      // 3.7 Compute holidayNamesValue as list of unique holiday names
      holidayNamesValue = Object.keys(holidayGroups);
      console.log('Holidays Count:', holidayNamesValue.length); // Debugging

      // 4. Compute dayOfWeek (1 for Monday, 7 for Sunday)
      const jsDayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
      dayOfWeekValue = jsDayOfWeek === 0 ? 7 : jsDayOfWeek;
      console.log('Day of Week:', dayOfWeekValue); // Debugging

      // 5. Compute isWeekend
      isWeekendValue = dayOfWeekValue >= 6 ? 1 : 0;
      console.log('Is Weekend:', isWeekendValue); // Debugging

      // 6. Compute daysToNextHoliday
      const futureHolidays = holidays
        .map((holiday) => {
          const holidayStart = startOfDay(parseISO(holiday.start_date));
          return {
            ...holiday,
            holidayStart,
          };
        })
        .filter((holiday) => {
          if (!isValid(holiday.holidayStart)) {
            console.warn('Invalid holiday start date:', holiday.start_date);
            return false;
          }

          return holiday.holidayStart > date; // Exclude holidays on or before the selected date
        });

      futureHolidays.sort((a, b) => {
        return a.holidayStart - b.holidayStart;
      });

      const nextHoliday = futureHolidays[0];

      daysToNextHolidayValue = nextHoliday
        ? Math.ceil(
            (nextHoliday.holidayStart - date) / (1000 * 60 * 60 * 24)
          )
        : 0; // If no future holidays, set to 0

      console.log('Days to Next Holiday:', daysToNextHolidayValue); // Debugging

      return {
        eventsCountValue,
        eventImportanceValue,
        holidayNamesValue,
        holidayEventInteractionValue,
        holidayEventImportanceValue,
        dayOfWeekValue,
        isWeekendValue,
        daysToNextHolidayValue,
      };
  };
  };
  // Function to handle getting the price suggestion
   const handleGetPriceSuggestion = async (date) => {
    if (!selectedAccommodation) {
      console.error('No accommodation selected.');
      setIsLoading(false);
      return;
    }

    try {
      setSuggestionButtonState('loading');
      setIsLoading(true);

      const {
        eventsCountValue,
        eventImportanceValue,
        holidayNamesValue,
        holidayEventInteractionValue,
        holidayEventImportanceValue,
        dayOfWeekValue,
        isWeekendValue,
        daysToNextHolidayValue,
      } = computeVariables(date, selectedAccommodation);

      setEventsCount(eventsCountValue);
      setEventImportance(eventImportanceValue);
      setHolidayNames(holidayNamesValue);
      setHolidayEventInteraction(holidayEventInteractionValue);
      setHolidayEventImportance(holidayEventImportanceValue);
      setDayOfWeek(dayOfWeekValue);
      setIsWeekend(isWeekendValue);
      setDaysToNextHoliday(daysToNextHolidayValue);

      const requestData = {
        events_count: eventsCountValue || 0,
        month: date.getMonth() + 1,
        day: date.getDate(),
        holiday: holidayNamesValue.length > 0 ? 1 : 0,
        event_importance: eventImportanceValue || 0,
        day_of_week: dayOfWeekValue,
        holiday_event_interaction: holidayEventInteractionValue || 1,
        holiday_event_importance: holidayEventImportanceValue || 1,
        prev_day_price_change: 0,
        prev_3_day_avg_price_change: 0,
        is_weekend: isWeekendValue ? 1 : 0,
        days_to_next_holiday: daysToNextHolidayValue || 0,
      };

      const response = await axios.post(
        'https://db74-139-144-66-74.ngrok-free.app/get_price_suggestion',
        requestData
      );

      if (response.data && response.data.suggestion !== undefined) {
        setPriceSuggestion(response.data.suggestion);
        setIsSuggestionBoxVisible(true);
        setSuggestionButtonState('completed');
      } else {
        console.error('Unexpected response format:', response);
        setSuggestionButtonState('error');
      }
    } catch (error) {
      console.error('Error fetching price suggestion:', error);
      setSuggestionButtonState('error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPriceSuggestion(null);
    setIsSuggestionBoxVisible(false);
    setSuggestionButtonState('idle');
  }, [selectedAccommodation, startDate]);

  const handleResetSuggestion = () => {
    setPriceSuggestion(null);
    setIsSuggestionBoxVisible(false);
    setSuggestionButtonState('idle');
  };

  if (currentUserId === null) {
    return null;
  }

  return (
    <div className="App" style={{ position: 'relative' }}>
{showInitialOverlay && (
  <div 
    className="overlay-blur" 
    style={{
      position: 'fixed',
      top:0, left:0, right:0, bottom:0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      backdropFilter: 'blur(5px)',
      zIndex: 9998,
      display: 'flex',
      justifyContent:'center',
      alignItems:'center'
    }}
  >
    <div 
      className="choose-accommodation-popup" 
      style={{
        backgroundColor:'#fff',
        padding:'2em',
        borderRadius:'1em',
        boxShadow:'0 0 10px rgba(0,0,0,0.5)',
        textAlign:'center',
        maxWidth:'300px',
        width:'100%'
      }}
    >
      <h2 style={{marginBottom:'1em', fontSize:'1em'}}>Válassz a szállásaid közül!</h2>
      
      {/* First accommodation-selector for the dropdown */}
      <div 
        className="accommodation-selector" 
        style={{ width: '100%', position:'relative' }} // Make it relative
      >
        <button
          className={`dropdown-button ${selectedAccommodation ? 'active' : ''}`}
          onClick={() => setAccommodationDropdownVisible(!accommodationDropdownVisible)}
          style={{ width:'100%', fontSize:'0.9em', textAlignLast: 'justify'}}
        >
          {selectedAccommodation
            ? selectedAccommodation.szallas_nev
            : 'Szállásaim 🡻'}
        </button>
        {accommodationDropdownVisible && (
          <div 
            className="dropdown-list accommodation-dropdown"
            style={{ 
              width:'100%',
              position:'absolute', // Absolute positioning
              top:'100%', // directly below the button
              left:0,
              right:0,
              margin:'0 auto',
              overflow: 'auto',
              maxHeight: '40vh',
              zIndex: 10000, // Higher z-index to appear above everything
              backgroundColor:'#C2C8C5' // Ensure a background to see the overlap
            }}
          >
            {accommodations.length > 0 ? (
              accommodations.map((accommodation) => (
                <div
                  key={accommodation.id}
                  className={`accommodation-item ${
                    selectedAccommodation === accommodation ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSelectedAccommodation(accommodation);
                    setMapCenter([accommodation.longitude, accommodation.latitude]);
                    setAccommodationDropdownVisible(false);
                  }}
                  style={{
                    display:'block',
                    width:'100%',
                    boxSizing:'border-box',
                    cursor:'pointer'
                  }}
                >
                  <strong>{accommodation.szallas_nev}</strong>
                  <br />
                  <small>{accommodation.szallas_cim}</small>
                </div>
              ))
            ) : (
              <p style={{ margin: '1em 0' }}>Nincs még szállásod, adj hozzá egyet mielőtt tovább lépnél.</p>
            )}
          </div>
        )}
      </div>

      {/* Separate accommodation-selector for the new button, placed below so dropdown floats above it */}
      <div className="accommodation-selector" style={{ width: '100%', marginTop:'1em', zIndex: 'auto !important' }}>
        <button
          className="dropdown-button"
          onClick={() => {
            window.location.href = "https://stai.hu/szallasaim/";
          }}
          style={{ textAlign:'left', fontSize:'0.9em', width:'100%' }}
        >
          Új szállás hozzáadása
        </button>
      </div>
    </div>
  </div>
)}


      <MapSection
        selectedAccommodation={selectedAccommodation}
        setSelectedAccommodation={setSelectedAccommodation}
        accommodations={accommodations}
        filteredVenues={filteredVenues}
        mapCenter={mapCenter}
        setMapCenter={setMapCenter}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        isBottomSectionOpen={isBottomSectionOpen}
        isDarkMode={isDarkMode} 
      />

      <TopSection
        accommodations={accommodations}
        selectedAccommodation={selectedAccommodation}
        setSelectedAccommodation={setSelectedAccommodation}
        setMapCenter={setMapCenter}
        currentView={currentView}
        setCurrentView={setCurrentView}
        startDate={startDate}
        setStartDate={setStartDate}
        filters={filters}
        setFilters={setFilters}
        handleGetPriceSuggestion={handleGetPriceSuggestion}
        suggestionButtonState={suggestionButtonState}
        handleResetSuggestion={handleResetSuggestion}
        isBottomSectionOpen={isBottomSectionOpen}
        setIsBottomSectionOpen={setIsBottomSectionOpen}
        bottomContainerHeight={bottomContainerHeight}
        toggleMapStyle={toggleMapStyle} 
        isDarkMode={isDarkMode}
      />

      <SuggestionSection
        eventsCount={eventsCount}
        eventImportance={eventImportance}
        holidayNames={holidayNames}
        holidayEventInteraction={holidayEventInteraction}
        holidayEventImportance={holidayEventImportance}
        prevDayPriceChange={0}
        prev3DayAvgPriceChange={0}
        isWeekend={isWeekend}
        daysToNextHoliday={daysToNextHoliday}
        selectedAccommodation={selectedAccommodation}
        startDate={startDate}
        priceSuggestion={priceSuggestion}
        isSuggestionBoxVisible={isSuggestionBoxVisible}
        handleResetSuggestion={handleResetSuggestion}
      />

      <div className="content-container">
        <div
          className={`main-content-horizontal ${
            isBottomSectionOpen ? 'bottom-open' : 'bottom-closed'
          }`}
        >
          <BottomSection
            filteredVenues={filteredVenues}
            setMapCenter={setMapCenter}
            setSelectedEvent={setSelectedEvent}
            holidays={holidays}
            startDate={startDate}
            isBottomSectionOpen={isBottomSectionOpen}
            setIsBottomSectionOpen={setIsBottomSectionOpen}
            bottomContainerHeight={bottomContainerHeight}
            setBottomContainerHeight={setBottomContainerHeight}
          />

          {currentView === 'calendar' && (
            <div
              className="calendar-overlay"
              onClick={() => setCurrentView('events-map')}
            >
              <div
                className="calendar-container-wrapper"
                onClick={(e) => e.stopPropagation()}
              >
                <CalendarView
                  startDate={startDate}
                  setStartDate={setStartDate}
                  events={events}
                  holidays={holidays}
                  selectedAccommodation={selectedAccommodation}
                  handleDateChange={handleDateChange}
                  SuggestionSectionProps={{
                    eventsCount,
                    eventImportance,
                    holidayNames,
                    holidayEventInteraction,
                    holidayEventImportance,
                    prevDayPriceChange:0,
                    prev3DayAvgPriceChange:0,
                    isWeekend,
                    daysToNextHoliday,
                    selectedAccommodation,
                    startDate,
                    priceSuggestion,
                    isSuggestionBoxVisible,
                    handleResetSuggestion,
                  }}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  suggestionButtonState={suggestionButtonState}
                  handleResetSuggestion={handleResetSuggestion}
                />
              </div> 
            </div> 
          )} 
        </div>
      </div>
    </div>
  );
}

export default App;