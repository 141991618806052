import React, { useState, useRef, useEffect } from 'react';
import './BottomSection.css';
import {
  startOfDay,
  endOfDay,
  parseISO,
  isWithinInterval,
  isValid,
  format,
} from 'date-fns';

function BottomSection({
  filteredVenues,
  setMapCenter,
  setSelectedEvent,
  holidays,
  startDate,
  isBottomSectionOpen,
  setIsBottomSectionOpen,
  bottomContainerHeight,
  setBottomContainerHeight,
}) {
  const [activeEvent, setActiveEvent] = useState(null);

  const isMobileDevice = () => {
    return (
      window.innerWidth <= 768 ||
      (window.innerWidth >= 768 &&
        window.innerWidth <= 1024 &&
        window.matchMedia('(orientation: portrait)').matches)
    );
  };

  const [isMobile, setIsMobile] = useState(isMobileDevice());

  const bottomContainerRef = useRef(null);
  const dragHeaderRef = useRef(null);
  const startY = useRef(0);
  const startHeight = useRef(0);

  useEffect(() => {
    const handleResize = () => {
      const isPortraitTablet = window.matchMedia(
        '(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)'
      ).matches;

      const isMobileDevice = window.innerWidth <= 768 || isPortraitTablet;
      setIsMobile(isMobileDevice);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const dragHeader = dragHeaderRef.current;
      if (dragHeader) {
        dragHeader.addEventListener('touchstart', handleTouchStart);
        dragHeader.addEventListener('touchmove', handleTouchMove);
        dragHeader.addEventListener('touchend', handleTouchEnd);

        dragHeader.addEventListener('mousedown', handleMouseDown);
      }
      return () => {
        if (dragHeader) {
          dragHeader.removeEventListener('touchstart', handleTouchStart);
          dragHeader.removeEventListener('touchmove', handleTouchMove);
          dragHeader.removeEventListener('touchend', handleTouchEnd);

          dragHeader.removeEventListener('mousedown', handleMouseDown);
        }
      };
    }
  }, [isMobile]);

  const handleTouchStart = (e) => {
    if (!isMobile) return;
    startY.current = e.touches[0].clientY;
    startHeight.current = bottomContainerRef.current.getBoundingClientRect().height;
  };

  const handleTouchMove = (e) => {
    if (!isMobile) return;
    const deltaY = startY.current - e.touches[0].clientY;
    let newHeight = startHeight.current + deltaY;
    const minHeight = 50; 
    const maxHeightPx = window.innerHeight * 0.47;
    newHeight = Math.max(minHeight, Math.min(maxHeightPx, newHeight));
    bottomContainerRef.current.style.height = `${newHeight}px`;
  };

  const handleTouchEnd = (e) => {
    if (!isMobile) return;
    const currentHeight = bottomContainerRef.current.getBoundingClientRect().height;
    const threshold = window.innerHeight * 0.2; 
    setIsBottomSectionOpen(currentHeight > threshold);
  };

  const handleMouseDown = (e) => {
    if (!isMobile) return;
    startY.current = e.clientY;
    startHeight.current = bottomContainerRef.current.getBoundingClientRect().height;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isMobile) return;
    const deltaY = startY.current - e.clientY;
    let newHeight = startHeight.current + deltaY;
    const minHeight = 50;
    const maxHeightPx = window.innerHeight * 0.47;
    newHeight = Math.max(minHeight, Math.min(maxHeightPx, newHeight));
    bottomContainerRef.current.style.height = `${newHeight}px`;
  };

  const handleMouseUp = (e) => {
    if (!isMobile) return;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    const currentHeight = bottomContainerRef.current.getBoundingClientRect().height;
    const threshold = window.innerHeight * 0.2; 
    setIsBottomSectionOpen(currentHeight > threshold);
  };

  useEffect(() => {
    if (bottomContainerRef.current) {
      if (isBottomSectionOpen) {
        bottomContainerRef.current.classList.add('open');
        bottomContainerRef.current.classList.remove('closed');

        if (isMobile) {
          // Mobile logic for sliding open
          bottomContainerRef.current.style.height = '0px';
          void bottomContainerRef.current.offsetHeight;
          bottomContainerRef.current.style.height = '47vh';
        } else {
          // Desktop logic for open (original was empty)
          bottomContainerRef.current.style.height = '';
        }
      } else {
        bottomContainerRef.current.classList.add('closed');
        bottomContainerRef.current.classList.remove('open');

        if (isMobile) {
          // Mobile logic for sliding closed
          const currentHeight = bottomContainerRef.current.getBoundingClientRect().height;
          bottomContainerRef.current.style.height = `${currentHeight}px`;
          void bottomContainerRef.current.offsetHeight;
          bottomContainerRef.current.style.height = '0px';
        } else {
          // Desktop logic for closed (original was empty)
          bottomContainerRef.current.style.height = '';
        }
      }
    }
  }, [isBottomSectionOpen, isMobile]);

  const handleToggle = () => {
    setIsBottomSectionOpen(!isBottomSectionOpen);
  };

  const eventIcons = {
    Zene: {
      colorless: '/wp-content/icons/microphone_colorless.png',
      colored: '/wp-content/icons/microphone.png',
    },
    Kultúra: {
      colorless: '/wp-content/icons/museum_colorless.png',
      colored: '/wp-content/icons/museum.png',
    },
    Sport: {
      colorless: '/wp-content/icons/sport_colorless.png',
      colored: '/wp-content/icons/sport.png',
    },
    Színház: {
      colorless: '/wp-content/icons/theatre_colorless.png',
      colored: '/wp-content/icons/theatre.png',
    },
  };

  // Compute events and holidays even if no accommodation is selected
  let sortedEvents = [];
  let holidayEvents = [];

  if (filteredVenues.length > 0) {
    // Create sortedEvents
    sortedEvents = filteredVenues
      .reduce((acc, venue) => {
        venue.events.forEach((event) => {
          acc.push({ ...event, venue });
        });
        return acc;
      }, [])
      .sort((a, b) => {
        const ratingA = a.event_rating || 0;
        const ratingB = b.event_rating || 0;
        if (ratingB !== ratingA) {
          return ratingB - ratingA;
        } else {
          return new Date(a.event_start) - new Date(b.event_start);
        }
      });

    // Filter holidays based on selected date
    const selectedDayStart = startOfDay(startDate);
    const selectedDayEnd = endOfDay(startDate);

    const holidaysOnSelectedDate = holidays.filter((holiday) => {
      const holidayStart = parseISO(holiday.start_date);
      const holidayEnd = holiday.end_date
        ? parseISO(holiday.end_date)
        : holidayStart;

      if (!isValid(holidayStart)) {
        console.warn('Invalid holiday start date:', holiday.start_date);
        return false;
      }

      if (!isValid(holidayEnd)) {
        console.warn('Invalid holiday end date:', holiday.end_date);
        return false;
      }

      return isWithinInterval(selectedDayStart, {
        start: startOfDay(holidayStart),
        end: endOfDay(holidayEnd),
      });
    });

    // Group holidays by holiday name
    const holidayGroups = holidaysOnSelectedDate.reduce((groups, holiday) => {
      const name = holiday.holiday;
      if (!groups[name]) {
        groups[name] = [];
      }
      groups[name].push(holiday);
      return groups;
    }, {});

    holidayEvents = Object.keys(holidayGroups).map((holidayName) => {
      const holidaysForName = holidayGroups[holidayName];
      const countries = holidaysForName.map((h) => h.country);
      const uniqueCountries = [...new Set(countries)];
      const countryText = uniqueCountries.join(', ');

      const startDates = holidaysForName.map((h) => parseISO(h.start_date));
      const endDates = holidaysForName.map((h) =>
        h.end_date ? parseISO(h.end_date) : parseISO(h.start_date)
      );
      const earliestStart = new Date(Math.min(...startDates));
      const latestEnd = new Date(Math.max(...endDates));

      return {
        id: `holiday-${holidayName}`,
        event_name: holidayName,
        event_type: 'Holiday',
        event_venue: countryText,
        event_start: format(earliestStart, 'yyyy-MM-dd'),
        event_end: format(latestEnd, 'yyyy-MM-dd'),
        event_rating: null,
        isHoliday: true,
        countries: uniqueCountries,
      };
    });
  }

  const combinedEvents = [...holidayEvents, ...sortedEvents].sort((a, b) => {
    if (a.isHoliday && !b.isHoliday) {
      return -1;
    } else if (!a.isHoliday && b.isHoliday) {
      return 1;
    } else if (a.isHoliday && b.isHoliday) {
      return new Date(a.event_start) - new Date(b.event_start);
    } else {
      const ratingA = a.event_rating || 0;
      const ratingB = b.event_rating || 0;
      if (ratingB !== ratingA) {
        return ratingB - ratingA;
      } else {
        return new Date(a.event_start) - new Date(b.event_start);
      }
    }
  });

  const handleEventClick = (event) => {
    if (event.isHoliday) return;

    setSelectedEvent(event);
    setActiveEvent(activeEvent === event.id ? null : event.id);

    const longitude = parseFloat(event.venue.longitude);
    const latitude = parseFloat(event.venue.latitude);
    setMapCenter([longitude, latitude]);
  };

useEffect(() => {
  if (!bottomContainerRef.current) return;

  // Only run logic for desktop
  if (!isMobile) {
    if (combinedEvents.length > 8) {
      bottomContainerRef.current.style.height = 'auto';
    } else {
      bottomContainerRef.current.style.height = 'fit-content';
    }
  }
  // If mobile, do nothing here. 
}, [combinedEvents.length, isMobile, isBottomSectionOpen, bottomContainerRef]);

  return (
    <div
      ref={bottomContainerRef}
      className={`bottom-container ${isBottomSectionOpen ? 'open' : 'closed'}`}
    >
      <div className="events">
        <button
          className="toggle-button"
          onClick={handleToggle}
          ref={isMobile ? dragHeaderRef : null}
        >
          {isMobile ? <div className="toggle-line"></div> : 'Események a közelben'}
        </button>
        <div className="content">
          {combinedEvents.length > 0 ? (
            combinedEvents.map((event) => {
              if (event.isHoliday) {
                const datesText =
                  event.event_end && event.event_end !== event.event_start
                    ? `${event.event_start} - ${event.event_end}`
                    : event.event_start;

                return (
                  <div
                    key={event.id}
                    className="holiday-item"
                    style={{ backgroundColor: '#a14343' }}
                  >
                    <div className="holiday-text">
                      <strong>{event.event_name}</strong>
                      <br />
                      {event.countries.length > 1 ? (
                        <marquee behavior="scroll" direction="left">
                          <small>{event.event_venue}</small>
                        </marquee>
                      ) : (
                        <small>{event.event_venue}</small>
                      )}
                      <br />
                      <div className="event-dates">{datesText}</div>
                    </div>
                  </div>
                );
              } else {
                const icon = eventIcons[event.event_type] || {};
                const backgroundImage =
                  activeEvent === event.id ? icon.colored : icon.colorless;
                const datesText = new Date(event.event_start).toLocaleDateString(
                  'hu-HU',
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                );

                return (
                  <button
                    key={event.id}
                    className={`event-button ${
                      activeEvent === event.id ? 'active' : ''
                    }`}
                    onClick={() => handleEventClick(event)}
                    style={{ '--background-image': `url(${backgroundImage})` }}
                  >
                    <div className="event-text">
                      <strong>{event.event_name}</strong>
                      <br />
                      <small>{event.event_venue}</small>
                      <br />
                      <div className="event-dates">{datesText}</div>
                    </div>
                  </button>
                );
              }
            })
          ) : (
            <p className="info-text">Nincsenek események.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default BottomSection;
